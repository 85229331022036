import QuoteSpotWrapper from "@components/layouts/quoteSpotWrapper";
import MainBanner from "@components/trafficbox/mainBanner";

import WhyQuoteSpot from "@components/trafficbox/whyQuoto";
import { getPageProps } from "@services/initial-calls";
import { GetServerSideProps } from "next";
import React, { ReactElement } from "react";

export default function QuoteSpotHome(): ReactElement {
    return (
        <QuoteSpotWrapper>
            <MainBanner />
            <WhyQuoteSpot />
        </QuoteSpotWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
